import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"

export default function Categories() {
  return (
    <section className="container-categories" id="categories">
      <div className="row narrow section-intro with-bottom-sep pb-4">
        <div className="col-twelve">
          {/* <h1 className="animate-this">Categories</h1> */}
          <h3 className="animate-this">We know how hard is to decide what type of tile or what layout, to do here some ideas for you</h3>
        </div>
      </div>
      <div className="categories">
        <div className="category">
          <AnchorLink to="/kitchen#kitchen" title="kitchen" className="link">
            <img src="/images/portfolio/categories/img-1.jpg" alt="" />
          </AnchorLink>
          <h4 className="mt-2 mb-0">Kitchen<br></br> 
          Backsplash</h4>
        </div>
        <div className="category">
          <AnchorLink to="/shower#shower" title="Bathroom's" className="link">
            <img src="/images/portfolio/categories/img-2.jpg" alt="" />
          </AnchorLink>

          <h4 className="mt-2 mb-0">Bathroom's</h4>
        </div>
         <div className="category">
          <AnchorLink to="/backsplash#backsplash" title="backsplash" className="link">
            <img src="/images/portfolio/categories/img-3.jpg" alt="" />
          </AnchorLink>
          <h4 className="mt-2 mb-0">Floors</h4>
        </div> 
      </div>
    </section>
  )
}
