import React from "react"

export default function HomePage() {
  return (
    <section id="home">
      <div className="overlay"></div>
      <div className="gradient-overlay"></div>
      <div className="home-content-table">
        <div className="home-content-tablecell">
          <div className="row">
            <div className="col-twelve">
              <div className="home-bottom-text">
                
                <h1
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="500"
                  data-sal-easing="ease-out"
                >
                  M.A Tile & Marble Works LLC  
                </h1>
                <h3 className="animate-intro">We are here to make your dream bathroom coming true not just your bathroom any tile project that you have in mind we make it happen...</h3>

                <ul className="home-section-links">
                  <li
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-duration="600"
                    data-sal-easing="ease-out"
                  >
                   Address{" "}
                    <a>
                    Madison - Connecticut
                    </a>
                  </li>
                  <li
                    data-sal="slide-up"
                    data-sal-delay="600"
                    data-sal-duration="600"
                    data-sal-easing="ease-out"
                  >
                    Phone{" "}
                    <a>
                    (203) 430-9302
                    </a>
                  </li>
                  {/* <li
                    data-sal="slide-up"
                    data-sal-delay="800"
                    data-sal-duration="600"
                    data-sal-easing="ease-out"
                  >
                    Cellphone{" "}
                    <a href="#contact" className="smoothscroll">
                      christian.collaguazom@gmail.com
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="#about" className="scroll-icon smoothscroll">
        <span className="icon-DownArrow"></span>
      </a>
    </section>
  )
}
