import React from "react"

export default function Contact() {
  return (
    <section id="contact" className="portfolio">
      <div className="overlay"></div>

      <div className="row narrow section-intro">
        <div className="col-twelve">
          <h1
            className="animate-this"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out"
          >
          
          </h1>
          <h3
            className="animate-this"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out"
          >
            Call us for your free quote
          </h3>

          {/* <p
            className="lead animate-this"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out"
          >
            Lorem ipsum Do commodo in proident enim in dolor cupidatat
            adipisicing dolore officia nisi aliqua incididunt Ut veniam lorem
            ipsum Consectetur ut in in eu do.
          </p> */}
        </div>
      </div>

      <div className="row contact-form">
        <div className="col-twelve">
          <form
            name="contactForm"
            id="contactForm"
            method="post"
            action="https://formspree.io/f/xbjwlqkg"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease-out"
          >
            <div className="form-field animate-this">
              <input
                name="name"
                type="text"
                id="contactName"
                placeholder="Name"
                defaultValue=""
                minLength={2}
                required
              />
            </div>
            <div className="form-field animate-this">
              <input
                name="contactEmail"
                type="email"
                id="contactEmail"
                placeholder="Email"
                defaultValue=""
                required
              />
            </div>
            {/* <div className="form-field animate-this">
              <input
                name="name"
                type="text"
                id="contactSubject"
                placeholder="Subject"
                defaultValue=""
              />
            </div> */}
            <div className="form-field animate-this">
              <textarea
                name="message"
                id="contactMessage"
                placeholder="message"
                rows={10}
                cols={50}
                required
              ></textarea>
            </div>

            <div className="form-field animate-this">
              <button className="submitform">Submit</button>

              <div id="submit-loader">
                <div className="text-loader">Sending...</div>
                <div className="s-loader">
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </div>
            </div>
          </form>

          <div id="message-warning"></div>

          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!
            <br />
          </div>
        </div>
      </div>
    </section>
  )
}
