import React, { Component } from "react"

import { AnchorLink } from "gatsby-plugin-anchor-links"

export default class Header extends Component {
  private headerElement: React.RefObject<HTMLDivElement>
  constructor(props) {
    super(props)
    this.headerElement = React.createRef()
    console.log(this.headerElement)
    // this.openNav = this.openNav.bind(this)
    // this.removeNav = this.removeNav.bind(this)
  }

  openNav(): void {
    this.headerElement.current.classList.add("menu-is-open")
  }

  removeNav(): void {
    this.headerElement.current.classList.remove("menu-is-open")
  }
  render() {
    return (
      <header className="header-page" ref={this.headerElement}>
        {/* <div className="header-logo">
          <a href="index.html">Howdy</a>
        </div> */}

        {/* <span
          id="header-menu-trigger"
          onClick={() => this.openNav()}
          className="d-md-none"
        >
           <span className="header-menu-text d-none d-md-inline">Menu</span> 
          <span className="header-menu-icon"></span>
        </span> */}

        <div className="logo">
          <img src="/images/logo.png" alt="logo del sitio web" />
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
            <button
              className="navbar-toggler m-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav text-secondary">
                <li className="nav-item">
                  <AnchorLink className="nav-link" to="/#home" title="Home">
                    Home
                  </AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink
                    className="nav-link"
                    to="/#categories"
                    title="Category"
                  >
                    Categories
                  </AnchorLink>
                </li>
                <li className="nav-item">
                  <AnchorLink className="nav-link" to="/#about" title="About">
                    About us
                  </AnchorLink>
                </li>

                <li className="nav-item">
                  <AnchorLink
                    className="nav-link"
                    to="/#contact"
                    title="Contact"
                  >
                    Contact
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <nav id="menu-nav-wrap">
          <a
            className="close-button"
            title="close"
            onClick={() => this.removeNav()}
          >
            <span>Cerrar</span>
          </a>

          <h3>Marble Works</h3>

          <ul className="nav-list">
            <li className="current">
              <AnchorLink to="/#home" title="">
                Home
              </AnchorLink>
            </li>
            <li className="current">
              <AnchorLink to="/#categories" title="">
                Categories
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/#about" title="">
                About
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/kitchen#kitchen" title="kitchen">
                Kitchen
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/shower#shower" title="shower">
                Showers
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/backsplash#backsplash" title="backsplash">
                Backsplash
              </AnchorLink>
            </li>

            <li>
              <AnchorLink to="/#contact" title="">
                Contact
              </AnchorLink>
            </li>
          </ul>

          <ul className="header-social-list">
            <li>
              <a href="#">
                <i className="fa fa-facebook-square"></i>
              </a>
            </li>
            <li>
              <a href="#">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/m.a_tile_?utm_medium=copy_link"
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>
        </nav> */}
      </header>
    )
  }
}
