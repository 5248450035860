import React from "react"
import Header from "./Header"
import "../styles/base.css"
import "../styles/vendor.css"
import "../styles/main.scss"

export default function Layout({ children }) {
  return (
    <div id=" top" className="layout ">
      <Header />
      <section>{children}</section>
      <footer>
         <div className="row contact-info">
        <div className="col-four tab-full mb-4">
          <h5 className="animate-this">Address</h5>

          <p className="animate-this">
            Madison -
            Connecticut
           
          </p>
        </div>

        <div className="col-four tab-full mb-4">
          <h5 className="animate-this">Email</h5>

          <p className="animate-this">
          matilemarble@gmail.com
          </p>
        </div>

        <div className="col-four tab-full mb-4">
          <h5 className="animate-this">Phone</h5>

          <p className="animate-this">
            Phone: (+203) 430 9302
          </p>
        </div>
      </div>
        <div className="row">
          <div className="col-twelve tab-full social">
            <ul className="footer-social">
                <li>
                <a href="https://vm.tiktok.com/ZMRUpBuVP/" >Tik Tok</a>
              </li>
              <li>
                <a href="https://instagram.com/m.a_tile_?utm_medium=copy_link" target="_blank">Instagram</a>
              </li>
            </ul>
          </div>

          <div className="col-twelve tab-full">
            <div className="copyright">
              <span>© Copyright Christian Collaguazo & William Sanchez 2022.</span>
            </div>
          </div>
        </div>

        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#top">
            <i className="icon-UpArrow"></i>
          </a>
        </div>
      </footer>
    </div>
  )
}
