import React from "react"

export default function About() {
  return (
    <section id="about">
      <div className="about-profile-bg"></div>

      <div className="about-wrap">
        <div className="about-profile">
          <div className="about-profile-bg"></div>
          <div className="about-profile-content">
            <div className="intro">
              <h3
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-out"
              >
                ABOUT US,
              </h3>
              {/* <h1
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-out"
              >
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              </h1> */}
              {/* <div
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-out"
              >
                <img
                  className="profile-image"
                  src="/images/profile-image.jpg"
                  alt=""
                />
              </div> */}

              <p
                className="lead"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease-out"
              >
              </p>
            </div>

            <p
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              "We are Tile contractor located in Madison CT, founded in 2012 our goal is to make our costumers 100% satisfied with our work."
            </p>
            <p
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              "We always make sure to use the best materials that are available in the market."
            </p>
            <p
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              "We are very careful, responsible and always on time with our projects, and also we are available for any questions from customers."
            </p>
            <p
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              We counting with:
              Liability and workers compensation insurance
            </p>
            <p
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              Lic#hic: 0637969
            </p>
            {/* <p
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-easing="ease-out"
            >
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel ad
              atque minus incidunt adipisci aliquam dolorem! Facere doloremque
              sapiente deserunt nemo necessitatibus ex voluptatibus aspernatur,
              voluptatem commodi pariatur! Quas, alias?
            </p> */}
          </div>
        </div>

        <div className="about-services">
          <div className="intro">
            <h3
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              Services
            </h3>
            <h1
              className="lead "
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
               We can help you with any of this projects:
            </h1>
            <p
              className="lead "
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              Bathroom Renovations, Costume Showers, Kitchen Backsplash, Tub Surrounding,
              Laundry Room, Foyers, Mudrooms and more...
            </p>
          </div>

          <ul className="services">
            {/* <li
              className="item-branding "
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              <div className="icon-wrap">
                <i className="icon-RegisteredTrademark"></i>
              </div>
              <div className="service-item">
                <h4>Branding</h4>
                <p>
                  Lorem ipsum Sit Ut eu Ut commodo velit ea et ex officia dolor
                  laborum do in quis ut in occaecat non Duis ut sint voluptate
                  esse consequat aute incididunt in voluptate incididunt.
                </p>
              </div>
            </li>
            <li
              className="item-photography"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              <div className="icon-wrap">
                <i className="icon-Picture"></i>
              </div>
              <div className="service-item">
                <h4>Photography</h4>
                <p>
                  Lorem ipsum Sit Ut eu Ut commodo velit ea et ex officia dolor
                  laborum do in quis ut in occaecat non Duis ut sint voluptate
                  esse consequat aute incididunt in voluptate incididunt.
                </p>
              </div>
            </li>
            */}
          </ul>
        </div>
      </div>
    </section>
  )
}
