import React from "react"
import Layout from "../components/Layout"
import About from "../components/About"
import HomePage from "../components/HomePage"

import Kitchen from "../components/Kitchen"

import Contact from "../components/Contact"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Showers from "../components/Showers"

import Categories from "../components/Categories"
import Revies from "../components/Revies"
export default function Home({ data }) {
  console.log(data.site.siteMetadata.title)
  

  return (
    <Layout>
      <SEO
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
        image={data.site.siteMetadata.image}
      />
      <HomePage />
      <Categories/>
      <About/>
      <Contact />
      <Revies/>
    </Layout>
  )
}

export const query = graphql`
  query SiteInfo {
    site {
      siteMetadata {
        author
        description
        image
        siteUrl
        title
      }
    }
  }
`
