import { Link } from "gatsby"
import React from "react"
import { NavLink } from "react-bootstrap"

export default function Revies(){
 return (
    <><link rel="preconnect" href="https://fonts.gstatic.com" /><><link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link><><script src="https://kit.fontawesome.com/c8e4d183c2.js"></script>
    <section id="testimonials">
         <div className="testimonial-heading">
             <span>Comments</span>
             <h1>Clients Says</h1>
         </div>

         <div className="testimonial-box-container">
         <div className="testimonial-box">

<div className="box-top">

    <div className="profile">

        <div className="name-user">
            <strong>Tom M.</strong>
            <span>Orange, CT</span>
        </div>
    </div>

    <div className="reviews">
        <i className="icon-Rating"></i>
        <i className="icon-Rating"></i>
        <i className="icon-Rating"></i>
        <i className="icon-Rating"></i>
        <i className="icon-Rating"></i>
    </div>
</div>

<div className="client-comment">
    <p>Did an excellent job! Well organized and very neat.</p>
</div>
</div>
<div className="testimonial-box">

                 <div className="box-top">

                     <div className="profile">

                         <div className="name-user">
                             <strong>Jim S.</strong>
                             <span>Berlin, CT</span>
                         </div>
                     </div>

                     <div className="reviews">
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                     </div>
                 </div>

                 <div className="client-comment">
                     <p>Excellent quality of work. Very hard working crew!</p>
                 </div>
             </div>
             <div className="testimonial-box">

                 <div className="box-top">

                     <div className="profile">

                         <div className="name-user">
                             <strong>Lisa S.</strong>
                             <span>Higganum, CT</span>
                         </div>
                     </div>

                     <div className="reviews">
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                     </div>
                 </div>

                 <div className="client-comment">
                     <p>Mike is very responsive and prompt, professional and courteous . the guys working for him were very respectful and conscious of the work area. they worked very cleanly and picked up after themselves. this job was very stressful for me I couldn't decide on the final tile and Mike eased my anxiety that everything would be fine. and it was...it is perfect!!</p>
                 </div>
             </div>

             <div className="testimonial-box">

                 <div className="box-top">

                     <div className="profile">
                         <div className="name-user">
                             <strong>Asije M.</strong>
                             <span>Watertown, CT.</span>
                         </div>
                     </div>

                     <div className="reviews">
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                     </div>
                 </div>

                 <div className="client-comment">
                     <p>Mike responded very fast and came to look at the job the next day! He gave us excellent price and started working right away, almost done with the project everything they do is perfect and they clean after themselves I would highly recommend them the best of the best!                                 </p>
                     <br></br>
                 </div>
             </div>

             <div className="testimonial-box">

                 <div className="box-top">
  
                     <div className="profile">


                         <div className="name-user">
                             <strong>John D.</strong>
                             <span>Seymour, CT.</span>
                         </div>
                     </div>

                     <div className="reviews">
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                     </div>
                 </div>

                 <div className="client-comment">
                 <p>Mike contacted us immediately when we sent the request and came out the same day. He gave us a reasonable quote and was able to start the project quicker than we expected. When we set a date Mike and his guys came right at the time they said they would. What was quoted as a 2-day job, possibly 3 was finished in one day; these guys worked throughout the entire day and finished everything. They were super accommodating of our 4-year-old pup who has the energy of a 4-month-old and also of our 3-year-old son. They were professional, courteous, conscientious of the CDC guidelines, and just did an amazing job. They tiled ceramic tile over glass tile I had installed when we first renovated our kitchen and you would not know the tile they installed had not been there originally. These guys are aces.</p>
                 </div>
             </div>

             <div className="testimonial-box">

                 <div className="box-top">

                     <div className="profile">

                         <div className="name-user">
                             <strong>Joseph D.</strong>
                             <span>East Haven, CT.</span>
                         </div>
                     </div>

                     <div className="reviews">
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                         <i className="icon-Rating"></i>
                     </div>
                 </div>

                 <div className="client-comment">
                 <p> Michael is very professional, knowledgeable, courteous and conscientious. Calls were returned promptly and the work was started when scheduled. The project was completed promptly and met every expectation for excellence. The workmen were very respectful of our home taking meticulous care not to dirty floors to and from work area and to clean up after work. I highly recommend M. A. Tile and Marble.</p>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 
                 </div>
             </div>
         </div>
     </section></></></>
 )
}
